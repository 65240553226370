import React from 'react';
import styled from '@emotion/styled';
import Container from '../Container';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';

// #region styles
const SDisclosure = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 30px;
  margin-top: 40px;
  color: ${COLORS.STRONG};
  font-size: 16px;
  font-style: italic;
  text-align: center;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;

  ${mq.tablet} {
    margin-top: 50px;
  }
`;
// endregion

const Disclosure = ({ text }) => (
  <Container>
    <SDisclosure>{text}</SDisclosure>
  </Container>
);

export default Disclosure;
