import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from '@emotion/styled';
import Container from '../Container';
import { mq } from '../../styles/functions/mq';
import { Heading3 } from '../Headings';
import { COLORS, FONTS } from '../../styles/settings/global';
import SmartLink from '../SmartLink';

// #region styles
const SLocations = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  ${mq.tablet} {
    padding: 40px 0;
    margin-top: 120px;
    background: url('/images/contact_bottom_pattern.png') center center
      no-repeat;
    background-size: auto 100%;
  }
`;

const SEmail = styled.div`
  color: ${COLORS.STRONG};
  font-family: ${FONTS.PORTADA};
  font-size: 15px;
  font-weight: bold;
`;

const SLocationList = styled.div`
  width: 100%;

  ${mq.tablet} {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;

const SLocation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 30px;
  text-align: center;

  ${mq.tablet} {
    margin: 0 25px;
  }

  &:first-of-type {
    margin-top: 40px;

    ${mq.tablet} {
      margin-top: 0;
    }
  }
`;

const SLocationAddress = styled.div`
  margin-top: 10px;
  white-space: pre;

  ${mq.tablet} {
    margin-top: 20px;
  }
`;
// endregion

const Locations = ({ button, locations }) => (
  <Container>
    <SLocations>
      <SmartLink
        {...button}
        className="text-dark-blue"
        onClick={() => {
          if (button.url.startsWith('mailto:')) {
            trackCustomEvent({
              category: 'contact interest',
              action: 'contact',
              label: 'hello-litmus-mailto',
            });
          }
        }}
      >
        <SEmail>{button.text}</SEmail>
      </SmartLink>
      <SLocationList>
        {locations.map((item, index) => {
          return (
            <SLocation key={index}>
              <Heading3>{item.title}</Heading3>
              <SLocationAddress>{item.location}</SLocationAddress>
            </SLocation>
          );
        })}
      </SLocationList>
    </SLocations>
  </Container>
);

export default Locations;
