import React from 'react';
import { Input, Label } from 'reactstrap';

const FormInput = ({
  label,
  name,
  type,
  value,
  placeholder,
  error,
  onBlur,
  onChange,
}) => (
  <>
    <Label className="contact-label" for={name}>
      {label}
    </Label>
    <Input
      id={name}
      className="green-border"
      type={type || 'text'}
      name={name}
      value={value}
      placeholder={placeholder !== undefined ? placeholder : label}
      onBlur={onBlur}
      onChange={onChange}
    />
    {error && (
      <span className="contact-error-text">Please enter a valid {label}</span>
    )}
  </>
);

export default FormInput;
