import React from 'react';
import { Form, Input, Label } from 'reactstrap';
import styled from '@emotion/styled';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import FormInput from './FormInput';
import { COLORS, FONTS } from '../../styles/settings/global';
import { mq } from '../../styles/functions/mq';
import { Heading3 } from '../Headings';
import Button from '../Button';

// #region styles
const SContactForm = styled.section`
  position: relative;
  left: -5px;
  width: calc(100% - 10px);
  max-width: 840px;
  margin: 80px auto 10px;

  ${mq.landscape} {
    margin: 80px auto 13px;
  }

  ${mq.desktop} {
    max-width: 1050px;
    margin: 150px auto 16px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 20px;
    right: 0;
    top: 20px;
    bottom: 0;
    box-shadow: 10px 10px 0 0 ${COLORS.SECONDARY};
    pointer-events: none;

    ${mq.landscape} {
      left: 0;
      top: 0;
      box-shadow: 13px 13px 0 0 ${COLORS.SECONDARY};
    }
    ${mq.desktop} {
      box-shadow: 16px 16px 0 0 ${COLORS.SECONDARY};
    }
  }
`;

const SForm = styled(Form)`
  padding: 30px 30px 80px;
  background: ${COLORS.STRONG};

  ${mq.tablet} {
    padding: 60px 60px 80px;
  }
`;

const SFormGroup = styled.div`
  ${mq.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const SInput = styled.div`
  position: relative;
  margin-top: 30px;

  & > label {
    color: white;
    font-size: 16px;
    font-family: ${FONTS.APERCU};
    font-weight: bold;

    ${mq.desktop} {
      font-size: 20px;
    }
  }

  & > input {
    ${mq.desktop} {
      height: 50px;
    }
  }

  & > input,
  & > textarea {
    font-family: ${FONTS.PORTADA};
    border-radius: 0;
    transition: border-color 0.25s, background-color 0.25s;

    ${({ error }) =>
      error
        ? `
      border-color: ${COLORS.ERROR};
      background-color: #FFDDDD;
    `
        : ``}
  }

  & > textarea {
    min-height: 160px;
  }

  & .check-box-text {
    font-family: ${FONTS.PORTADA};
    font-weight: normal;
  }

  ${mq.tablet} {
    width: ${({ fullsize }) => (fullsize ? '100%' : 'calc(50% - 25px)')};
  }
`;

const SError = styled.div`
  position: absolute;
  right: 5px;
  color: white;
`;

const SHeading3 = styled(Heading3)`
  color: white;
  margin-bottom: 40px;

  ${mq.tablet} {
    margin-bottom: 0;
  }
`;

const SWrapper = styled.div`
  display: flex;
  margin-top: 70px;
`;

const SConfirmationMessage = styled.div`
  width: 100%;
  margin-left: -100%;
  flex-shrink: 0;
  transition: opacity 0.5s;
  color: #fff;

  ${({ visible }) =>
    visible
      ? `
    opacity: 1;
    pointer-events: all;
  `
      : `
    opacity: 0;
    pointer-events: none;
  `};
`;

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  transition: opacity 0.5s;

  ${({ visible }) =>
    visible
      ? `
    opacity: 1;
    pointer-events: all;
  `
      : `
    opacity: 0;
    pointer-events: none;
  `};
`;

// endregion

class ContactForm extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    workText: '',
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    organizationError: false,
    mailing: false,
    formSend: false,
  };

  mailingRef = React.createRef();

  formRef = React.createRef();

  handleChecked() {
    this.setState((state) => ({ mailing: !state.mailing }));
  }

  onChange = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  onBlur = (key, event) => {
    this.setState({ [key]: event.target.value });
    this.isEmpty(key, event.target.value);
  };

  isEmpty = (key, value) => {
    const result = !value;
    const errorKey = `${key}Error`;
    this.setState({ [errorKey]: result });
    return result;
  };

  submitForm = async () => {
    const { firstName, lastName, email, organization } = this.state;
    if (
      this.isEmpty('firstName', firstName) ||
      this.isEmpty('lastName', lastName) ||
      this.isEmpty('email', email) ||
      this.isEmpty('organization', organization)
    ) {
      return;
    }

    const form = this.formRef.current.querySelector('form');
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(new FormData(form)).toString(),
    });

    this.setState({ formSend: true });

    trackCustomEvent({
      category: 'lead aquired',
      action: 'contact form',
      label: 'contact page',
    });
    trackCustomEvent({
      category: 'contact form',
      action: 'success',
      label: 'contact page',
    });
  };

  render() {
    const {
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      email,
      emailError,
      organization,
      organizationError,
      workText,
      formSend,
    } = this.state;

    return (
      <SContactForm ref={this.formRef}>
        <SForm name="Contact Form" data-netlify="true" method="POST">
          <input type="hidden" name="form-name" value="Contact Form" />
          <SHeading3>Contact Us</SHeading3>
          <SFormGroup>
            <SInput error={firstNameError}>
              <FormInput
                label="First Name"
                name="firstName"
                placeholder=""
                value={firstName}
                onChange={(e) => this.onChange('firstName', e)}
                onBlur={(e) => this.onBlur('firstName', e)}
              />
              <SError>
                {firstNameError && `Please enter your first name`}
              </SError>
            </SInput>
            <SInput error={lastNameError}>
              <FormInput
                label="Last Name"
                name="lastName"
                placeholder=""
                value={lastName}
                onChange={(e) => this.onChange('lastName', e)}
                onBlur={(e) => this.onBlur('lastName', e)}
              />
              <SError>{lastNameError && `Please enter your last name`}</SError>
            </SInput>
          </SFormGroup>
          <SFormGroup>
            <SInput error={emailError}>
              <FormInput
                label="Email"
                name="email"
                type="email"
                placeholder=""
                value={email}
                onChange={(e) => this.onChange('email', e)}
                onBlur={(e) => this.onBlur('email', e)}
              />
              <SError>
                {emailError && `Please enter a valid email address`}
              </SError>
            </SInput>
            <SInput error={organizationError}>
              <FormInput
                label="Organization/Institution"
                name="organization"
                placeholder=""
                value={organization}
                onChange={(e) => this.onChange('organization', e)}
                onBlur={(e) => this.onBlur('organization', e)}
              />
              <SError>
                {organizationError && `Please enter organization/institution`}
              </SError>
            </SInput>
          </SFormGroup>
          <SFormGroup>
            <SInput fullsize>
              <FormInput
                label="Tell us more about your work:"
                name="workText"
                type="textarea"
                value={workText}
                placeholder=""
                onChange={(e) => this.onChange('workText', e)}
              />
            </SInput>
          </SFormGroup>
          <SInput fullsize>
            <Label check>
              <Input
                ref={this.mailingRef}
                onChange={() => {
                  this.handleChecked();
                }}
                type="checkbox"
                name="mailing"
                value="checked"
              />{' '}
              <span className="check-box-text">
                I would like to be included in your mailing list to receive
                future updates on Litmus Health products and announcements
              </span>
            </Label>
          </SInput>

          <SWrapper>
            <SButtonWrapper visible={!formSend}>
              <Button
                color={COLORS.SECONDARY}
                onClick={() => {
                  this.submitForm();
                }}
              >
                Let&apos;s talk
              </Button>
            </SButtonWrapper>

            <SConfirmationMessage visible={formSend}>
              <p className="contact-submit-text1">THANK YOU!</p>
              <div className="contact-submit-text2">
                <div>We&apos;ve received your message and will get</div>
                <div>back to you as soon as possible</div>
              </div>
            </SConfirmationMessage>
          </SWrapper>
        </SForm>
      </SContactForm>
    );
  }
}

export default ContactForm;
