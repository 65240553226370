import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SplitHero from '../components/contact/SplitHero';
import Disclosure from '../components/contact/Disclosure';
import Locations from '../components/contact/Locations';
import MediaInformation from '../components/contact/MediaInformation';
import ContactForm from '../components/contact/ContactForm';
import { DownloadModalFormForNetlify } from '../components/DownloadModal';

const ContactPage = (props) => {
  const { data, location } = props;
  const { meta, hero, disclosures, locations } = data.contactYaml;

  return (
    <Layout meta={meta} location={location}>
      <SplitHero
        title={hero.title}
        description={hero.description}
        note={hero.note}
        image={hero.image?.publicURL}
        imageAlt={hero.imageAlt}
        button={hero.button}
      />

      <DownloadModalFormForNetlify />
      <ContactForm />
      {disclosures.map((disclosure, index) => (
        <Disclosure key={index} text={disclosure.disclosure} />
      ))}
      <Locations button={locations.button} locations={locations.locations} />
      <MediaInformation />
    </Layout>
  );
};

export default ContactPage;

// #region graphql
export const pageQuery = graphql`
  query ContactPage {
    contactYaml {
      meta {
        title
        description
      }
      hero {
        title
        description
        note
        image {
          publicURL
        }
        imageAlt
        button {
          text
          url
          newTab
        }
      }
      disclosures {
        disclosure
      }
      locations {
        button {
          url
          text
          newTab
        }
        locations {
          title
          location
        }
      }
    }
  }
`;
// endregion
