import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Heading1, Heading2 } from '../Headings';
import Container from '../Container';
import Button from '../Button';
import { COLORS } from '../../styles/settings/global';
import { mq } from '../../styles/functions/mq';
import Background from '../Background';
import SmartLink from '../SmartLink';

// #region styles
const SSplitHero = styled.section`
  position: relative;

  ${mq.tablet} {
    display: flex;
  }
`;

const SDecoration = styled(Background)`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 85px;
  }
`;

const SGraphic = styled.div`
  position: relative;
  width: 100%;
  padding: 80px 30px 40px;

  ${mq.tablet} {
    width: 42%;
    order: 1;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SGraphicImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const SContent = styled.div`
  padding: 80px 30px;
  background: ${COLORS.LIGHT};

  ${mq.tablet} {
    width: 58%;
    padding: 160px 30px 80px;
  }

  ${mq.landscape} {
    padding: 160px 60px 80px 120px;
  }

  ${mq.desktop} {
    padding: 180px 180px 180px 180px;
  }
`;

const SHeading1Mobile = styled(Heading1)`
  color: white;

  ${mq.tablet} {
    display: none;
  }
`;

const SHeading1Desktop = styled(Heading1)`
  display: none;

  ${mq.tablet} {
    display: block;
    color: ${COLORS.PRIMARY};
  }
`;

const SDescription = styled.div`
  ${mq.tablet} {
    max-width: 360px;
  }

  ${mq.desktop} {
    max-width: 565px;
  }

  h1 + & {
    ${mq.tablet} {
      margin-top: 40px;
    }
  }
`;

const SHeading2 = styled(Heading2)`
  color: ${COLORS.PRIMARY};

  h1 + &,
  div + & {
    margin-top: 40px;
  }
`;

const CButton = css`
  margin-top: 25px;
`;
// endregion

const SplitHero = ({ title, description, note, image, imageAlt, button }) => (
  <Container>
    <SSplitHero>
      <SDecoration src="../images/1-DatalinesBar.png" />
      <SGraphic>
        {image && <SGraphicImage src={image} alt={imageAlt} />}
        {title && <SHeading1Mobile>{title}</SHeading1Mobile>}
        <SmartLink {...button}>
          <Button
            color={COLORS.SECONDARY}
            gap="side"
            cssStyle={CButton}
            onClick={() => {
              if (button.url.startsWith('mailto:')) {
                trackCustomEvent({
                  category: 'contact interest',
                  action: 'contact',
                  label: 'introductory-call-mailto',
                });
              }
            }}
          >
            {button.text}
          </Button>
        </SmartLink>
      </SGraphic>
      <SContent>
        {title && <SHeading1Desktop>{title}</SHeading1Desktop>}
        {description && <SDescription>{description}</SDescription>}
        {note && <SHeading2>{note}</SHeading2>}
      </SContent>
    </SSplitHero>
  </Container>
);

export default SplitHero;
