import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from '@emotion/styled';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import Container from '../Container';

// #region styles
const SMediaInformation = styled.div`
  max-width: 260px;
  padding: 0 30px;
  margin: 40px auto 80px;
  text-align: center;

  ${mq.tablet} {
    max-width: none;
  }
`;

const SLink = styled.span`
  color: ${COLORS.STRONG};
  white-space: nowrap;
  font-weight: bold;
`;

// endregion

const MediaInformation = () => (
  <Container>
    <SMediaInformation>
      <span>For media inquiries and/or information, please visit our </span>
      <OutboundLink
        href="http://press.litmushealth.com/?utm_source=litmus&utm_medium=contact_page&utm_campaign=press_link"
        target="_blank"
      >
        <SLink>press page.</SLink>
      </OutboundLink>
    </SMediaInformation>
  </Container>
);

export default MediaInformation;
